@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry


@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/Proxima Nova Font.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: local('Gilda Display'), url('./fonts/futura/futura medium bt.ttf') format('woff');
}


body {
    margin: 0;
    font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Futura';
  }


  button.link {
    background: none;
    border: none;
    color:#0d1b2a;
    cursor: pointer;
  }

.ant-table-tbody>tr.ant-table-row-selected>td, .ant-table-tbody>tr.ant-table-row-selected:hover>td {
  background: #ffffff !important;
}
@primary-color: #7FB367; // primary color for all components
