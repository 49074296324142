.float-right {
    float: right;

}

.pointer {
    cursor: pointer;
}

.ml-5 {
    margin-left: 5rem;
}
.mr-5 {
    margin-right: 5rem;
}

.Mapper__colr {
    padding-right: 13px;
}

.Mapper__coll {
    padding-left: 13px;
}

.formItem {
    float: right;
    margin-bottom: 15px;
    margin-right: 30px;
}