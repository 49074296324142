.Signup__header {
    text-align: center;
    margin-top: 2em;
}

.Signup__container {
    width: 500px;
    margin: auto;
    margin-top: 3em;
    margin-bottom: 5em;
    background-color: #f9f9f9;
    border: 1px solid f9f9f9;
    padding: 2em 3em;
}

.Signup__wrapper {
    min-height: calc(100vh - 100px);
}

.Signup input {
    height: 3em;
}

.ant-input-affix-wrapper.ant-input-password {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
}

.Signup button {
    height: 3em;
}

.Signup__item {
    margin-bottom: 0.75em !important;
}

.Signup .error {
    color: #ff4d4f;
    margin-bottom: 1em;
    margin-top: 0;
}

.Signup__resend_code {
    float: right;
    margin-bottom: 20px;
}