.ChangePassword__container {
    width: 500px;
    margin: auto;
    margin-top: 5em;
    margin-bottom: 5em;
    background-color: #f9f9f9;
    border: 1px solid f9f9f9;
    padding: 3em;
}

.ChangePassword__wrapper {
    min-height: calc(100vh - 100px);
    width: 400px;
}

.ChangePassword input {
    height: 3.2em;
}

.ChangePassword button {
    height: 3.2em;
}

.ChangePassword__resend {
    float: right;
    margin-top: 4px;
}

.ChangePassword__item {
    margin-bottom: 1.5em;
}

.ChangePassword .error {
    color: #ff4d4f;
    margin-bottom: 1em;
    margin-top: 0;
}

.ChangePassword .success {
    color: #127605;
    margin-bottom: 1em;
    margin-top: 0;
    font-size: 16px;
}