.ForgotPassword__container {
    width: 500px;
    margin: auto;
    margin-top: 5em;
    margin-bottom: 5em;
    background-color: #f9f9f9;
    border: 1px solid f9f9f9;
    padding: 3em;
}

.ForgotPassword__wrapper {
    min-height: calc(100vh - 100px);
}

.ForgotPassword input {
    height: 3.2em;
}

.ForgotPassword button {
    height: 3.2em;
}

.ForgotPassword__resend {
    float: right;
    margin-top: 4px;
}

.ForgotPassword__item {
    margin-bottom: 1.5em;
}

.ForgotPassword .error {
    color: #ff4d4f;
    margin-bottom: 1em;
    margin-top: 0;
}