.App__logo {
  width: 220px;
  height: 50px;
  float: left;
  background-repeat: no-repeat;
  background-size: auto;
  text-align: left;
}


.App__logo .logo {
  width: 220px;
  height: 60px;
  float: left;
  margin: 0px 15px 15px 15px;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.ml-5 {
  margin-left: 5em;
}

.mr-5 {
  margin-right: 5em;
}

.mt-5 {
  margin-top: 5em;
}

.mb-5 {
  margin-bottom: 5em;
}

.mt-4 {
  margin-top: 4em;
}

.mb-4 {
  margin-bottom: 4em;
}

.m-4 {
  margin: 4em;
}

.m-3 {
  margin: 3em;
}

.m-2 {
  margin: 2em;
}

.m-1 {
  margin: 1em;
}

.ant-layout-header.header {
  height: 98px;
  padding: 0 15px;
  background-color: white;
}

.ant-layout-header.header h1,
.ant-layout-header.header h1 a {
  line-height: 55px;
  color: #000;
}

.App__menu {
  float: right;
  color: #fff;
  font-size: 1.2rem;
  line-height: 60px;
}

.App__menu_icon {
  padding: 0 5px;
  color: #fff;
}

.ant-layout-content.content {
  background-color: white;
}

.login {
  color: white !important;
}

.ant-btn-secondary {
  background-color: #7FB367 !important;
  color: white !important;
}
