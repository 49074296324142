.Login__header {
    text-align: center;
    margin-top: 2em;
}

.Login__container {
    width: 500px;
    margin: auto;
    margin-top: 3em;
    margin-bottom: 5em;
    background-color: #f9f9f9;
    border: 1px solid f9f9f9;
    padding: 3em;
}

.Login__wrapper {
    min-height: calc(100vh - 100px);
}

.Login input {
    height: 3em;
}

.Login button {
    height: 3.2em;
}

.ant-input-affix-wrapper.ant-input-password {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
}

.Login__forgot_password {
    float: right;
    margin-top: -10px;
}

.Login__item {
    margin-bottom: 1.5em;
}

.Login .error {
    color: #ff4d4f;
    margin-bottom: 1em;
    margin-top: 0;
}


.register-card__text {
    display: flex;
    flex-direction: row;
    margin: 1.2rem 0;
    color: #6f7880;
    font-size: 0.75rem;
  }
  .register-card__text::before,
  .register-card__text::after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }
  .register-card__text::before {
    margin-right: 10px;
  }
  .register-card__text::after {
    margin-left: 10px;
  }
  