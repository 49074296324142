.Jobs {
    margin-top: 25px;
    margin-left: 10px;
}

.Jobs__action {
    margin: 0 5px;
    font-size: 17px;
    color: black;
}

.Jobs__action:hover {
    color: #454545;
}