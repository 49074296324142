
.usage {
    height: 10px;
    width: 100%;
    border: 0;
    border-radius: 5px;
  }
  
  .inter,
  .outer {
    height: 100%;
    border-radius: 5px;
  }
  
  .usage .outer {
    background: rgba(127, 179, 103, 0.4);
  }
  
  .usage .inner {
    background: #7FB367 !important;
    border-radius: 5px;
    height: 100%;
  }
  .Account__card{
    padding: 10px;
  }
  .Account__dollar {
    color:#7FB367;
    font-size: 1.7rem;
  }

  .Account__frequency {
    font-size: 0.7rem;
    margin-left: 5px;
  }

  .Account__content {
    margin-top: 10px;
  }

  .Account__currentplan {
    padding: 10px;
    border: 1px solid #7FB367;
    background-color: #e8f2e4;
  }